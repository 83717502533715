import { useLexicalComposerContext } from "@lexical/react/LexicalComposerContext";
import { useCallback, useEffect, useState } from "react";
import {
  CAN_REDO_COMMAND,
  CAN_UNDO_COMMAND,
  REDO_COMMAND,
  UNDO_COMMAND,
  SELECTION_CHANGE_COMMAND,
  FORMAT_TEXT_COMMAND,
  FORMAT_ELEMENT_COMMAND,
  $getSelection,
  $isRangeSelection,
  $createParagraphNode,
  $isElementNode,
  $isRootOrShadowRoot,
  COMMAND_PRIORITY_CRITICAL,
  ElementFormatType,
  INDENT_CONTENT_COMMAND,
  LexicalEditor,
  OUTDENT_CONTENT_COMMAND,
  $createTextNode,
  KEY_DOWN_COMMAND,
} from "lexical";
import {
  $isParentElementRTL,
  $wrapNodes
} from "@lexical/selection";
import {
  $getNearestNodeOfType,
  mergeRegister,
  $findMatchingParent,
  $insertFirst,
  $insertNodeToNearestRoot,
} from "@lexical/utils";
import {
  INSERT_ORDERED_LIST_COMMAND,
  INSERT_UNORDERED_LIST_COMMAND,
  REMOVE_LIST_COMMAND,
  $isListNode,
  ListNode
} from "@lexical/list";
import {
  $isHeadingNode,
} from "@lexical/rich-text";
import { $isTableNode } from "@lexical/table";
import { $isAtNodeEnd } from '@lexical/selection';
import { ElementNode, RangeSelection, TextNode } from 'lexical';
import { InsertTableDialog } from './TablePlugin';
import useModal from '../../ui/hooks/useModal';
import DropDown, { DropDownItem } from "../../ui/DropDown";
import { Button, Divider, FormControlLabel, IconButton, Switch, ToggleButton, Toolbar } from "@mui/material";
import { ContentPasteGo, Difference, FormatBold, FormatItalic, FormatUnderlined, Redo, Undo, SuperscriptRounded, SubscriptRounded } from "@mui/icons-material";
import { blue } from "@mui/material/colors";


const blockTypeToBlockName: Record<string, string> = {
  ol: "Numbered List",
  paragraph: "Normal",
  ul: "Bulleted List"
};

const rootTypeToRootName = {
  root: 'Root',
  table: 'Table',
};

const ELEMENT_FORMAT_OPTIONS: {
  [key in Exclude<ElementFormatType, ''>]: {
    icon: string;
    iconRTL: string;
    name: string;
  };
} = {
  center: {
    icon: 'center-align',
    iconRTL: 'center-align',
    name: 'Center Align',
  },
  end: {
    icon: 'right-align',
    iconRTL: 'left-align',
    name: 'End Align',
  },
  justify: {
    icon: 'justify-align',
    iconRTL: 'justify-align',
    name: 'Justify Align',
  },
  left: {
    icon: 'left-align',
    iconRTL: 'left-align',
    name: 'Left Align',
  },
  right: {
    icon: 'right-align',
    iconRTL: 'left-align',
    name: 'Right Align',
  },
  start: {
    icon: 'left-align',
    iconRTL: 'right-align',
    name: 'Start Align',
  },
};

function getSelectedNode(
  selection: RangeSelection,
): TextNode | ElementNode {
  const anchor = selection.anchor;
  const focus = selection.focus;
  const anchorNode = selection.anchor.getNode();
  const focusNode = selection.focus.getNode();
  if (anchorNode === focusNode) {
    return anchorNode;
  }
  const isBackward = selection.isBackward();
  if (isBackward) {
    return $isAtNodeEnd(focus) ? anchorNode : focusNode;
  } else {
    return $isAtNodeEnd(anchor) ? anchorNode : focusNode;
  }
}

function dropDownActiveClass(active: boolean) {
  if (active) return 'active dropdown-item-active';
  else return '';
}

function BlockFormatDropDown({
  editor,
  blockType,
  rootType,
  disabled = false,
}: {
  blockType: keyof typeof blockTypeToBlockName;
  rootType: keyof typeof rootTypeToRootName;
  editor: LexicalEditor;
  disabled?: boolean;
}): JSX.Element {
  const formatParagraph = () => {
    if (blockType !== "paragraph") {
      editor.update(() => {
        const selection = $getSelection();
        if ($isRangeSelection(selection)) {
          $wrapNodes(selection, () => $createParagraphNode());
        }
      });
    }
  };

  const formatBulletList = () => {
    if (blockType !== 'bullet') {
      editor.dispatchCommand(INSERT_UNORDERED_LIST_COMMAND, undefined);
    } else {
      editor.dispatchCommand(REMOVE_LIST_COMMAND, undefined);
    }
  };

  const formatNumberedList = () => {
    if (blockType !== 'number') {
      editor.dispatchCommand(INSERT_ORDERED_LIST_COMMAND, undefined);
    } else {
      editor.dispatchCommand(REMOVE_LIST_COMMAND, undefined);
    }
  };

  return (
    <DropDown
      disabled={disabled}
      buttonLabel={blockTypeToBlockName[blockType]}
      buttonAriaLabel="Formatting options for text style">
      <DropDownItem
        className={'item ' + dropDownActiveClass(blockType === 'paragraph')}
        onClick={formatParagraph}>
        <i className="icon paragraph" />
        <span className="text">Normal</span>
      </DropDownItem>
      <DropDownItem
        className={'item ' + dropDownActiveClass(blockType === 'bullet')}
        onClick={formatBulletList}>
        <i className="icon bullet-list" />
        <span className="text">Bullet List</span>
      </DropDownItem>
      <DropDownItem
        className={'item ' + dropDownActiveClass(blockType === 'number')}
        onClick={formatNumberedList}>
        <i className="icon numbered-list" />
        <span className="text">Numbered List</span>
      </DropDownItem>
    </DropDown>
  );
}

function ElementFormatDropdown({
  editor,
  value,
  isRTL,
  disabled = false,
}: {
  editor: LexicalEditor;
  value: ElementFormatType;
  isRTL: boolean;
  disabled: boolean;
}) {
  const formatOption = ELEMENT_FORMAT_OPTIONS[value || 'left'];

  return (
    <DropDown
      disabled={disabled}
      buttonLabel={formatOption.name}
      buttonAriaLabel="Formatting options for text alignment">
      <DropDownItem
        onClick={() => {
          editor.dispatchCommand(FORMAT_ELEMENT_COMMAND, 'left');
        }}
        className="item">
        <i className="icon left-align" />
        <span className="text">Left Align</span>
      </DropDownItem>
      <DropDownItem
        onClick={() => {
          editor.dispatchCommand(FORMAT_ELEMENT_COMMAND, 'center');
        }}
        className="item">
        <i className="icon center-align" />
        <span className="text">Center Align</span>
      </DropDownItem>
      <DropDownItem
        onClick={() => {
          editor.dispatchCommand(FORMAT_ELEMENT_COMMAND, 'right');
        }}
        className="item">
        <i className="icon right-align" />
        <span className="text">Right Align</span>
      </DropDownItem>
      <DropDownItem
        onClick={() => {
          editor.dispatchCommand(FORMAT_ELEMENT_COMMAND, 'justify');
        }}
        className="item">
        <i className="icon justify-align" />
        <span className="text">Justify Align</span>
      </DropDownItem>
      <DropDownItem
        onClick={() => {
          editor.dispatchCommand(FORMAT_ELEMENT_COMMAND, 'start');
        }}
        className="item">
        <i
          className={`icon ${isRTL
            ? ELEMENT_FORMAT_OPTIONS.start.iconRTL
            : ELEMENT_FORMAT_OPTIONS.start.icon
            }`}
        />
        <span className="text">Start Align</span>
      </DropDownItem>
      <DropDownItem
        onClick={() => {
          editor.dispatchCommand(FORMAT_ELEMENT_COMMAND, 'end');
        }}
        className="item">
        <i
          className={`icon ${isRTL
            ? ELEMENT_FORMAT_OPTIONS.end.iconRTL
            : ELEMENT_FORMAT_OPTIONS.end.icon
            }`}
        />
        <span className="text">End Align</span>
      </DropDownItem>
      <Divider orientation="vertical" />
      <DropDownItem
        onClick={() => {
          editor.dispatchCommand(OUTDENT_CONTENT_COMMAND, undefined);
        }}
        className="item">
        <i className={'icon ' + (isRTL ? 'indent' : 'outdent')} />
        <span className="text">Outdent</span>
      </DropDownItem>
      <DropDownItem
        onClick={() => {
          editor.dispatchCommand(INDENT_CONTENT_COMMAND, undefined);
        }}
        className="item">
        <i className={'icon ' + (isRTL ? 'outdent' : 'indent')} />
        <span className="text">Indent</span>
      </DropDownItem>
    </DropDown>
  );
}

interface ToolbarPluginProps {
  hideFormat?: boolean;
  hideInsert?: boolean;
  hideAlign?: boolean;
  diffToggle?: boolean;
  extraToolbarElement?: React.ReactElement;
  textToImport?: string[];
  onChangeDiff?: (b: boolean) => void;
  displayStripToggle?: boolean;
}

export default function ToolbarPlugin({
  hideFormat = false,
  hideInsert = false,
  hideAlign = false,
  diffToggle = false,
  extraToolbarElement,
  textToImport,
  onChangeDiff,
  displayStripToggle = false
}: ToolbarPluginProps): JSX.Element {
  const [editor] = useLexicalComposerContext();
  const [activeEditor, setActiveEditor] = useState(editor);
  const [blockType, setBlockType] =
    useState<keyof typeof blockTypeToBlockName>('paragraph');
  const [rootType, setRootType] =
    useState<keyof typeof rootTypeToRootName>('root');
  const [elementFormat, setElementFormat] = useState<ElementFormatType>('left');
  const [isBold, setIsBold] = useState(false);
  const [isItalic, setIsItalic] = useState(false);
  const [isUnderline, setIsUnderline] = useState(false);
  const [canUndo, setCanUndo] = useState(false);
  const [canRedo, setCanRedo] = useState(false);
  const [modal, showModal] = useModal();
  const [isRTL, setIsRTL] = useState(false);
  const [isEditable, setIsEditable] = useState(() => editor.isEditable());
  const [showDiff, setShowDiff] = useState<boolean>(diffToggle);
  const [stripReturns, setStripReturns] = useState(displayStripToggle);

  useEffect(() => {
    if (onChangeDiff) {
      onChangeDiff(showDiff);
    }
  }, [showDiff])

  const $updateToolbar = useCallback(() => {
    const selection = $getSelection();
    if ($isRangeSelection(selection)) {
      const anchorNode = selection.anchor.getNode();
      let element =
        anchorNode.getKey() === 'root'
          ? anchorNode
          : $findMatchingParent(anchorNode, (e) => {
            const parent = e.getParent();
            return parent !== null && $isRootOrShadowRoot(parent);
          });

      if (element === null) {
        element = anchorNode.getTopLevelElementOrThrow();
      }

      const elementKey = element.getKey();
      const elementDOM = activeEditor.getElementByKey(elementKey);

      // Update text format
      setIsBold(selection.hasFormat('bold'));
      setIsItalic(selection.hasFormat('italic'));
      setIsUnderline(selection.hasFormat('underline'));
      setIsRTL($isParentElementRTL(selection));

      // Update links
      const node = getSelectedNode(selection);
      const parent = node.getParent();

      const tableNode = $findMatchingParent(node, $isTableNode);
      if ($isTableNode(tableNode)) {
        setRootType('table');
      } else {
        setRootType('root');
      }

      if (elementDOM !== null) {
        if ($isListNode(element)) {
          const parentList = $getNearestNodeOfType<ListNode>(
            anchorNode,
            ListNode,
          );
          const type = parentList
            ? parentList.getListType()
            : element.getListType();
          setBlockType(type);
        } else {
          const type = $isHeadingNode(element)
            ? element.getTag()
            : element.getType();
          if (type in blockTypeToBlockName) {
            setBlockType(type as keyof typeof blockTypeToBlockName);
          }

        }
      }

      setElementFormat(
        $isElementNode(node)
          ? node.getFormatType()
          : parent?.getFormatType() || 'left',
      );
    }
  }, [activeEditor]);

  useEffect(() => {
    return editor.registerCommand(
      SELECTION_CHANGE_COMMAND,
      (_payload, newEditor) => {
        $updateToolbar();
        setActiveEditor(newEditor);
        return false;
      },
      COMMAND_PRIORITY_CRITICAL,
    );
  }, [editor, $updateToolbar]);

  useEffect(() => {
    return mergeRegister(
      editor.registerEditableListener((editable) => {
        setIsEditable(editable);
      }),
      activeEditor.registerUpdateListener(({ editorState }) => {
        editorState.read(() => {
          $updateToolbar();
        });
      }),
      activeEditor.registerCommand<boolean>(
        CAN_UNDO_COMMAND,
        (payload) => {
          setCanUndo(payload);
          return false;
        },
        COMMAND_PRIORITY_CRITICAL,
      ),
      activeEditor.registerCommand<boolean>(
        CAN_REDO_COMMAND,
        (payload) => {
          setCanRedo(payload);
          return false;
        },
        COMMAND_PRIORITY_CRITICAL,
      ),
      editor.registerCommand(
        KEY_DOWN_COMMAND,
        (event) => {
          if ((event.ctrlKey || event.metaKey) && event.key === ".") {
            event.preventDefault();
            editor.update(() => {
              const selection = $getSelection();
              if ($isRangeSelection(selection)) {
                editor.dispatchCommand(FORMAT_TEXT_COMMAND, "superscript");
              }
            });
            return true;
          } else if ((event.ctrlKey || event.metaKey) && event.key === ",") {
            event.preventDefault();
            editor.update(() => {
              const selection = $getSelection();
              if ($isRangeSelection(selection)) {
                editor.dispatchCommand(FORMAT_TEXT_COMMAND, "subscript");
              }
            });
            return true;
          }
          return false;
        },
        COMMAND_PRIORITY_CRITICAL
      )
    );
  }, [$updateToolbar, activeEditor, editor]);

  return (
    <Toolbar
      variant="dense"
      disableGutters
      sx={{
        padding: 1,
        minHeight: 0
      }}
    >
      <IconButton
        disabled={!canUndo || !isEditable}
        onClick={() => {
          activeEditor.dispatchCommand(UNDO_COMMAND, undefined);
        }}
        title={'Undo (Ctrl+Z)'}
        type="button"
        size="small"
        aria-label="Undo"
        sx={{
          padding: '4px 5px'
        }}
      >
        <Undo fontSize="small" />
      </IconButton>
      <IconButton
        disabled={!canRedo || !isEditable}
        onClick={() => {
          activeEditor.dispatchCommand(REDO_COMMAND, undefined);
        }}
        title={'Redo (Ctrl+Y)'}
        type="button"
        size="small"
        aria-label="Redo"
        sx={{
          padding: '4px 5px'
        }}
      >
        <Redo fontSize="small" />
      </IconButton>
      <Divider flexItem orientation="vertical" sx={{ mx: 1 }} />
      {(blockType in blockTypeToBlockName && activeEditor === editor && !hideFormat) && (
        <>
          <BlockFormatDropDown
            disabled={!isEditable}
            blockType={blockType}
            rootType={rootType}
            editor={editor}
          />
          <Divider flexItem orientation="vertical" sx={{ mx: 1 }} />
        </>
      )}
      <>
        <IconButton
          disabled={!isEditable}
          onClick={() => {
            activeEditor.dispatchCommand(FORMAT_TEXT_COMMAND, 'bold');
          }}
          sx={{
            background: (isBold ? blue[50] : ''),
            padding: '4px 5px'
          }}
          title={'Bold (Ctrl+B)'}
          type="button"
          aria-label={`Format text as bold. Shortcut: ${'Ctrl+B'}`}
        >
          <FormatBold fontSize="small" />
        </IconButton>
        <IconButton
          disabled={!isEditable}
          onClick={() => {
            activeEditor.dispatchCommand(FORMAT_TEXT_COMMAND, 'italic');
          }}
          sx={{
            marginLeft: '3px',
            background: (isItalic ? blue[50] : ''),
            padding: '4px 5px'
          }}
          title={'Italic (Ctrl+I)'}
          type="button"
          aria-label={`Format text as italics. Shortcut: ${'Ctrl+I'}`}
        >
          <FormatItalic fontSize="small" />
        </IconButton>
        <IconButton
          disabled={!isEditable}
          onClick={() => {
            activeEditor.dispatchCommand(FORMAT_TEXT_COMMAND, 'underline');
          }}
          sx={{
            marginLeft: '3px',
            background: (isUnderline ? blue[50] : ''),
            padding: '4px 5px'
          }}
          title={'Underline (Ctrl+U)'}
          type="button"
          size="medium"
          aria-label={`Format text to underlined. Shortcut: ${'Ctrl+U'}`}
        >
          <FormatUnderlined fontSize="small" />
        </IconButton>
        <IconButton
          disabled={!isEditable}
          onClick={() => {
            activeEditor.dispatchCommand(FORMAT_TEXT_COMMAND, 'superscript');
          }}
          sx={{
            background: (isBold ? blue[50] : ''),
            padding: '4px 5px'
          }}
          title={'Superscript (Ctrl+B)'}
          type="button"
          aria-label={`Format text as superscript.`}
        >
          <SuperscriptRounded fontSize="small" />
        </IconButton>
        <IconButton
          disabled={!isEditable}
          onClick={() => {
            activeEditor.dispatchCommand(FORMAT_TEXT_COMMAND, 'subscript');
          }}
          sx={{
            background: (isBold ? blue[50] : ''),
            padding: '4px 5px'
          }}
          title={'Subscript'}
          type="button"
          aria-label={`Format text as subscript.`}
        >
          <SubscriptRounded fontSize="small" />
        </IconButton>
        {!hideInsert && (
          <>
            <Divider flexItem orientation="vertical" sx={{ mx: 1 }} />
            <DropDown
              disabled={!isEditable}
              buttonLabel="Insert"
              buttonAriaLabel="Insert specialized editor node"
            >
              <DropDownItem
                onClick={() => {
                  showModal('Insert Table', (onClose) => (
                    <InsertTableDialog
                      activeEditor={activeEditor}
                      onClose={onClose}
                    />
                  ));
                }}
                className="item">
                <i className="icon table" />
                <span className="text">Table</span>
              </DropDownItem>
            </DropDown>
          </>
        )}
      </>
      {!hideAlign && (
        <>
          <Divider flexItem orientation="vertical" sx={{ mx: 1 }} />
          <ElementFormatDropdown
            disabled={!isEditable}
            value={elementFormat}
            editor={editor}
            isRTL={isRTL}
          />
        </>
      )}
      {diffToggle && (
        <>
          <Divider flexItem orientation="vertical" sx={{ mx: 1 }} />
          <FormControlLabel 
            control={(
              <Switch
                checked={showDiff}
                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                  setShowDiff(event.target.checked);
                }}
                inputProps={{ 'aria-label': 'controlled' }}
              />
            )} 
            label="Diff" 
          />
        </>
      )}
      {extraToolbarElement && (
        <>
          <Divider flexItem orientation="vertical" sx={{ mx: 1 }} />
          {extraToolbarElement}
        </>
      )}
      {!!textToImport && (
        <>
          <Divider flexItem orientation="vertical" sx={{ mx: 1 }} />
          {displayStripToggle && (<FormControlLabel 
            control={(
              <Switch
                checked={stripReturns}
                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                  setStripReturns(!stripReturns);
                }}
                inputProps={{ 'aria-label': 'controlled' }}
                aria-label={`Strips returns from import text.`}
              />
            )} 
            label="Strip Returns" 
          />)}
          <Button
            size="small"
            color="inherit"
            aria-label={`Import text from highlights.`}
            onClick={() => {
              if (textToImport && textToImport.length > 0) {
                editor.update(() => {
                  const paragraph = $createParagraphNode();
                  let text = textToImport.join("\n");
                  if(stripReturns) {
                    text = text.replace(/[\r\n]/g, "");
                  }
                  $insertFirst(paragraph, $createTextNode(text));
                  $insertNodeToNearestRoot(paragraph);
                });
              }
            }}
          >
            <ContentPasteGo fontSize="small" color="inherit" />
            &nbsp;Text
          </Button>
        </>
      )}
      {modal}
    </Toolbar>
  );
}