import React, { useState } from "react";
import { useParams } from "react-router-dom";
import {
  Tabs,
  Tab,
  Box
} from "@mui/material/";
import SideNav from "../../components/navigation/SideNav";
import BuilderEditor from "./builderEditor"

import MemoTemplateLexicalEditor from "../../components/lexical/wrappers/MemoTemplateEditor";
import PropertiesEditor from "./propertiesEditor";
import { useGetBuilderDocumentQuery, useGetBuilderQuery } from "../../redux/services/builder";
import BuilderEditorV2 from "./builderEditorV2";
import ModelSettings from "./modelSettings";
import TabPanel, { a11yProps } from "../../components/navigation/TabPanel";
import GuidanceAssignment from "./GuidanceAssignment";
import { Builder } from "../../redux/models/dataModelTypes";
import Simulation from "./simulation";
import { BuilderDocumentProvider } from "./SpecBuilder/CustomCommentaryWidget/context";
import BuilderQuestionGeneratedPrompts from "./BuilderQuestionGeneratedPrompts";
import {
  QueryClient,
  QueryClientProvider
} from '@tanstack/react-query';
import BuilderReportScripts from "./BuilderReportScripts";

const queryClient = new QueryClient()

interface InnerBuilderProps {
  builder: Builder;
  builderDocumentId: string;
}

const InnerBuilder = ({
  builder,
  builderDocumentId
}: InnerBuilderProps) => {
  const [tabValue, setTabValue] = useState(0);
  const { data: builderDocument } = useGetBuilderDocumentQuery(builderDocumentId);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => setTabValue(newValue);

  return (
    <BuilderDocumentProvider builderDocument={builderDocument}>
      <div
        style={{
          padding: "10px",
          height: "100%"
        }}
      >
        <Tabs
          value={tabValue}
          onChange={handleChange}
        >
          <Tab label="General" {...a11yProps(0)} />
          <Tab label="Configuration" {...a11yProps(1)} />
          <Tab label="Builder" {...a11yProps(2)} />
          <Tab label="Memo Template" {...a11yProps(3)} />
          <Tab label="Model Integrations" {...a11yProps(4)} />
          <Tab label="Guidance" {...a11yProps(5)} />
          <Tab label="Simulation" {...a11yProps(6)} />
          <Tab label="Generated Prompts" {...a11yProps(7)} />
          <Tab label="Report Scripts" {...a11yProps(8)} />
        </Tabs>
        <TabPanel value={tabValue} index={0}>
          <PropertiesEditor builder={builder} />
        </TabPanel>
        <TabPanel value={tabValue} index={1}>
          {builderDocument && (
            <BuilderEditor builderDocument={builderDocument} />
          )}
        </TabPanel>
        <TabPanel value={tabValue} index={2}>
          {builderDocument && (
            <BuilderEditorV2 initialBuilderDocument={builderDocument} />
          )}
        </TabPanel>
        <TabPanel value={tabValue} index={3}>
          {builderDocument && (
            <MemoTemplateLexicalEditor builderDocument={builderDocument} />
          )}
        </TabPanel>
        <TabPanel value={tabValue} index={4}>
          {builderDocument && (
            <ModelSettings builderDocumentId={builderDocument.id} />
          )}
        </TabPanel>
        <TabPanel value={tabValue} index={5}>
          {builderDocument && (
            <GuidanceAssignment builderDocument={builderDocument} />
          )}
        </TabPanel>
        <TabPanel value={tabValue} index={6}>
          <Box
            height="100%"
            sx={{
              overflowY: "scroll"
            }}
          >
            {builderDocument && (
              <Simulation builderDocument={builderDocument} />
            )}
          </Box>
        </TabPanel>
        <TabPanel value={tabValue} index={7}>
          <Box
            height="100%"
            sx={{
              overflowY: "scroll"
            }}
          >
            <BuilderQuestionGeneratedPrompts />
          </Box>
        </TabPanel>
        <TabPanel value={tabValue} index={8}>
          <Box
            height="100%"
            sx={{
              overflowY: "scroll"
            }}>
              <BuilderReportScripts builderDocument={builderDocument} />
            </Box>
        </TabPanel>
      </div>
    </BuilderDocumentProvider>
  );
}

const BuilderWrapper = () => {
  const { id } = useParams<{ id: any }>();
  const { data: builder } = useGetBuilderQuery(id);
  return (
    <QueryClientProvider client={queryClient}>
      <SideNav>
        {builder && builder?.builderDocuments[0] && (
          <InnerBuilder
            builder={builder}
            builderDocumentId={builder.builderDocuments[0].id}
          />
        )}
      </SideNav>
    </QueryClientProvider>
  );
};

export default BuilderWrapper;
