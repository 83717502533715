import { CLEAR_HISTORY_COMMAND } from 'lexical';
import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext';
import { useEffect } from 'react';
import { useWidgetState } from '../../../containers/WidgetWrapper/wrapper';
import { MemoGenerationState } from '../../../containers/WidgetWrapper/states';

interface OnChangeAnalysisPluginProps {
    memoGenData: any;
    isSuccess: boolean;
}

const OnChangeAnalysisPlugin = ({
    memoGenData,
    isSuccess
}: OnChangeAnalysisPluginProps) => {
    const [editor] = useLexicalComposerContext();
    const { mutateState } = useWidgetState();

    useEffect(() => {
        if (editor && !!memoGenData) {
            try {
                const editorState = editor.parseEditorState(
                    memoGenData
                );
                editor.setEditorState(editorState);
                editor.dispatchCommand(CLEAR_HISTORY_COMMAND, undefined);
                mutateState<MemoGenerationState>({
                    currentEditor: editor
                });
            } catch (e) {
                //console.log(e);
            }
        }
    }, [isSuccess, memoGenData]);

    return null;
}

export default OnChangeAnalysisPlugin;