import React, { useEffect, useState } from "react";
import { 
    BuilderDocument,
    BuilderDocumentReportScript
} from "../../../redux/models/dataModelTypes";
import { TextField } from "@mui/material";
import { Check, Close } from "@mui/icons-material";
import {
    Box,
    Button,
    Grid, 
    Table, 
    TableContainer, 
    TableHead, 
    TableCell,
    TableBody,
    TableRow
  } from "@mui/material/";

import { Delete } from "@mui/icons-material";
import { CrudButtonGroup } from "../../../components/buttons/crudButtonGroup";
import { 
    useAddBuilderDocumentReportScriptMutation, 
    useRemoveBuilderDocumentReportScriptMutation,
    useUpdateBuilderDocumentReportScriptMutation 
} from "../../../redux/services/builder";

interface Props {
    builderDocument: BuilderDocument | undefined;
}

const BuilderReportScripts = ({ builderDocument }: Props) => {
    const [name, setName] = useState("");
    const [reportScript, setReportScript] = useState("");
    const [editingReportScriptId, setEditingReportScriptId] = useState<string | null>(null);
    const [editingReportScript, setEditingReportScript] = useState("");
    const [editingName, setEditingName] = useState("");
    const [editingNameId, setEditingNameId] = useState<string | null>(null);
    
    const [addBuilderDocumentReportScript] = useAddBuilderDocumentReportScriptMutation();
    const [removeBuilderDocumentReportScript] = useRemoveBuilderDocumentReportScriptMutation();
    const [updateBuilderDocumentReportScript] = useUpdateBuilderDocumentReportScriptMutation();
    return (
        <>
            <Grid container>
                <Grid
                    xs={12}
                    item
                    paddingTop={1}
                    paddingBottom={2}
                >
                    <TableContainer>
                        <Table>
                            <TableHead>
                                <TableCell
                                    variant="head"
                                    sx={{ fontWeight: "bolder" }}
                                >
                                    Name
                                </TableCell>
                                <TableCell
                                    variant="head"
                                    sx={{ fontWeight: "bolder" }}
                                >
                                    Script
                                </TableCell>
                                <TableCell
                                    variant="head"
                                    sx={{ fontWeight: "bolder" }}
                                >
                                    Created At
                                </TableCell>
                                <TableCell />
                            </TableHead>
                            <TableBody>
                                {(builderDocument && builderDocument.builderDocumentReportScripts.length > 0) ? builderDocument?.builderDocumentReportScripts.map((bdrs: BuilderDocumentReportScript) => (
                                    <TableRow
                                        key={bdrs.id}
                                        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                    >
                                        <TableCell 
                                            component="th" 
                                            scope="row"
                                            onDoubleClick={() => {
                                                setEditingNameId(bdrs.id);
                                                setEditingName(bdrs.name);
                                            }}
                                            sx={{ 
                                                cursor: 'pointer',
                                                '&:hover': {
                                                    backgroundColor: 'rgba(0, 0, 0, 0.04)'
                                                },
                                                maxHeight: '100px',
                                                overflow: 'hidden',
                                                textOverflow: 'ellipsis'
                                            }}
                                        >
                                            {editingNameId === bdrs.id ? (
                                                <Box sx={{ display: 'flex', gap: 1, alignItems: 'center' }}>
                                                    <TextField
                                                        value={editingName}
                                                        onChange={(e) => setEditingName(e.target.value)}
                                                        size="small"
                                                        autoFocus
                                                    />
                                                    <Button
                                                        size="small"
                                                        onClick={() => {
                                                            if (builderDocument) {
                                                                updateBuilderDocumentReportScript({
                                                                    builderDocumentId: builderDocument.id,
                                                                    id: bdrs.id,
                                                                    name: editingName,
                                                                    reportScript: bdrs.reportScript
                                                                });
                                                            }
                                                            setEditingNameId(null);
                                                        }}
                                                    >
                                                        <Check fontSize="small" />
                                                    </Button>
                                                    <Button
                                                        size="small"
                                                        onClick={() => setEditingNameId(null)}
                                                    >
                                                        <Close fontSize="small" />
                                                    </Button>
                                                </Box>
                                            ) : (
                                                bdrs.name
                                            )}
                                        </TableCell>
                                        <TableCell 
                                            component="th" 
                                            scope="row" 
                                            onDoubleClick={() => {
                                                setEditingReportScriptId(bdrs.id);
                                                setEditingReportScript(bdrs.reportScript);
                                            }}
                                            sx={{ 
                                                cursor: 'pointer',
                                                '&:hover': {
                                                    backgroundColor: 'rgba(0, 0, 0, 0.04)'
                                                },
                                                maxHeight: '100px',
                                                maxWidth: '500px',
                                                overflow: 'hidden',
                                                textOverflow: 'ellipsis',
                                                whiteSpace: 'nowrap'
                                            }}
                                        >
                                            {editingReportScriptId === bdrs.id ? (
                                                <Box sx={{ display: 'flex', gap: 1, alignItems: 'flex-start' }}>
                                                    <TextField
                                                        value={editingReportScript}
                                                        onChange={(e) => setEditingReportScript(e.target.value)}
                                                        multiline
                                                        fullWidth
                                                        size="small"
                                                        autoFocus
                                                        sx={{
                                                            maxHeight: '200px',
                                                            overflowY: 'auto'
                                                            // '& .MuiInputBase-root': {
                                                            //     maxHeight: '200px'
                                                            // }
                                                        }}
                                                    />
                                                    <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
                                                        <Button
                                                            size="small"
                                                            onClick={() => {
                                                                if (builderDocument) {
                                                                    updateBuilderDocumentReportScript({
                                                                        builderDocumentId: builderDocument.id,
                                                                        id: bdrs.id,
                                                                        name: bdrs.name,
                                                                        reportScript: editingReportScript
                                                                    });
                                                                }
                                                                setEditingReportScriptId(null);
                                                            }}
                                                        >
                                                            <Check fontSize="small" />
                                                        </Button>
                                                        <Button
                                                            size="small"
                                                            onClick={() => setEditingReportScriptId(null)}
                                                        >
                                                            <Close fontSize="small" />
                                                        </Button>
                                                    </Box>
                                                </Box>
                                            ) : (
                                                bdrs.reportScript
                                            )}
                                        </TableCell>
                                        <TableCell style={{ width: 160 }} align="right">
                                            {new Date(bdrs.createdAt).toLocaleString()}
                                        </TableCell>
                                        <TableCell style={{ width: 160 }} align="right">
                                            <CrudButtonGroup
                                                buttons={[{
                                                    icon: (<Delete fontSize="small" />),
                                                    handleClick: () => {
                                                        if (builderDocument) {
                                                            removeBuilderDocumentReportScript({
                                                                builderDocumentId: builderDocument.id,
                                                                id: bdrs.id
                                                            });
                                                        }
                                                    }
                                                }]}
                                            />
                                        </TableCell>
                                    </TableRow>
                                )) : (
                                    <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                        <TableCell colSpan={4} align="left">
                                            <i>none</i>
                                        </TableCell>
                                    </TableRow>
                                )}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Grid>
            </Grid>

            <Box
                sx={{
                paddingTop: "10px",
                height: "10%"
                }}
            >
                <TextField
                    label="Name"
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                    size="small"
                />
                <TextField
                    label="Report Script"
                    value={reportScript}
                    onChange={(e) => setReportScript(e.target.value)}
                    multiline
                    rows={4}
                    size="small"
                    sx={{ minWidth: 300 }}
                />
                <Button 
                    variant="contained" 
                    onClick={() => {
                        if (builderDocument && name && reportScript) {
                            addBuilderDocumentReportScript({
                                builderDocumentId: builderDocument.id,
                                name: name,
                                reportScript: reportScript
                            });
                            setName("");
                            setReportScript("");
                        }
                    }}
                    disabled={!name || !reportScript}
                >
                    Add
                </Button>
            </Box>
        </>)

}

export default BuilderReportScripts;